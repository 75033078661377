import { observable, action, runInAction } from 'mobx';
import { ErrorMessage } from '../../shared/interfaces';
import { WinningSelectionInterface } from '../../shared/interfaces/GamesPicker';
import { InstantTicketInterface } from '../../shared/interfaces/GamesPicker/TicketInterface';

import InstantGameStage from '../entities/InstantGameStage';
import ResultEntity from '../entities/ResultEntity';
import LotteryEntity from '../entities/selections/LotteryEntity';
import WonSelection from '../entities/WonSelection';
import PlayerBalanceStore from './PlayerBalanceStore';

import ApiInstantGame from '../services/ApiInstantGame';
import ApiInstantGameConnector from '../services/ApiInstantGameConnector';
import { TICKET_STATUS_WON } from '../utils/ticketsConstans';

const api = new ApiInstantGame();
const apiConnector = new ApiInstantGameConnector(api);

export default class InstantGameStore {
  private ticket: InstantTicketInterface;
  public lottery: LotteryEntity;
  @observable stage: InstantGameStage = new InstantGameStage();
  @observable error: ErrorMessage = null;
  @observable isLoading = false;
  @observable winningSelection: ResultEntity = null;

  constructor(public playerBalance: PlayerBalanceStore) {}

  @action
  postInstantBet = async (gameId: string, token: string): Promise<void> => {
    const body = this.lottery.toPlain();
    this.isLoading = true;

    try {
      const response: InstantTicketInterface = await apiConnector.postInstantTickets(body, gameId, token);

      runInAction(() => {
        const selection = response.bet.lines[0].wonSelection;
        this.ticket = response;
        this.winningSelection = new ResultEntity(selection);
        this.saveWinningSelectionToLines();
        this.stage.onTimeoutClear = this.lottery.lines.setNewLines;
        this.stage.setProperStage(response.bet.status);
        this.stage.setDisplaySelection();
        this.stage.startTimeout();
        this.playerBalance.getPlayerBalance(gameId);
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
        this.stage.setErrorStage();
        this.stage.startTimeout();
      });
    } finally {
      this.isLoading = false;
    }
  };

  saveWinningSelectionToLines(): void {
    const bet = this.ticket.bet;
    const wonSelectionParts = this.ticket.winningSelectionPartsPerLine;
    const lines = this.lottery.lines.linesList;

    if (bet.status !== TICKET_STATUS_WON) {
      return null;
    }

    bet.lines.forEach((elem, index) => {
      const winningSelection = wonSelectionParts[elem.id];
      const line = lines[index];
      const wonSelection = new WonSelection();

      if (!winningSelection || !elem.won) {
        return;
      }

      line.selectionsArray.forEach((elem) => {
        const properWinningSelections: WinningSelectionInterface = wonSelection.setWinningSelection(
          winningSelection,
          elem.isPermutationOn
        );
        const properElemWinningSelection = properWinningSelections[elem.name] || [];

        elem.wonSelection = properElemWinningSelection;
      });
    });
  }
}
