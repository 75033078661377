import React, { useCallback, useContext, useMemo } from 'react';

import { RootStoreContext } from '../../lobby-engine/stores/RootStore';

import TicketSummary from '../components/GamesPicker/TicketSummary/TicketSummary';
import { getDateAsString, getDatesRangeFromDraws, getErrorMessage } from '../../shared/utils';

import Money from '../../lobby-engine/entities/BetCostEntity';
import { TICKET_MODAL, ERROR_MODAL, MULTI_TICKET_RECEIPT_MODAL } from '../../shared/utils/modalNames';
import { ConfigContext } from '../../shared/context';

interface TicketSummaryProps {
  onBackClick: () => void;
  cancellation?: boolean;
}

const TicketSummaryContainer: React.FC<TicketSummaryProps> = ({ onBackClick, cancellation }: TicketSummaryProps) => {
  const { game, betDetails, modal, retail } = useContext(RootStoreContext);
  const token = useContext(ConfigContext).userToken;
  const lottery = useMemo(() => game.currentLottery, [game]);
  const bet = useMemo(() => betDetails.betDetails, [betDetails]);

  const onSubmitClick = useCallback(async (): Promise<void> => {
    try {
      modal.showModal(MULTI_TICKET_RECEIPT_MODAL, {});
      await retail.postNormalBet(token);
    } catch (error) {
      const errorData = getErrorMessage(error.statusCode, error.slugCode);
      modal.showModal(ERROR_MODAL, { errorData, code: error.statusCode });
    }
  }, [token, modal]);

  const onCancelClick = useCallback((): void => {
    modal.showModal(TICKET_MODAL, { isPayout: false, canceled: true });
  }, [modal]);

  const getSummaryData = useCallback(() => {
    if (cancellation) {
      return {
        image: bet.gameDraw.game.image,
        name: bet.gameDraw.game.displayedName,
        lines: betDetails.lines.data.length,
        drawsRange: getDateAsString(bet.gameDraw.drawDatetime, 'YYYY/MM/DD'),
        drawsCount: bet.betsCountInTransaction,
        stakes: new Money(bet.cachedStats.totalRealCost).toString(),
        betCost: new Money(bet.realLineCost).toString(),
      };
    }

    return {
      image: lottery.image,
      name: lottery.name,
      lines: lottery.lines.validLinesCount,
      drawsRange: getDatesRangeFromDraws(lottery.draws.selectedDraws),
      drawsCount: lottery.draws.selectedNumberDraws,
      stakes: lottery.stakes.selectedStake.value,
      betCost: lottery.ticket.betCost.toString(),
    };
  }, [bet, lottery, cancellation, betDetails.lines.data]);

  return (
    <TicketSummary {...{ onBackClick, cancellation, onCancelClick, onSubmitClick }} summaryData={getSummaryData()} />
  );
};

export default TicketSummaryContainer;
