import { createContext } from 'react';

import BetDetailsStore from './BetDetailsStore';
// import DrawsStore from './DrawsStore';
import InstructionsStore from './InstructionsStore';
// import LinesStore from './LinesStore';
import ModalStore from './ModalStore';
import UserBetsStore from './UserBetsStore';
import GamesStore from './GameStore';
// import StakeStore from './StakeStore';
// import TicketStore from './TicketStore';
// import LineCostStore from './LineCostStore';
import ConfigStore from './ConfigStore';
import I18nStore from './I18nStore';
import AppConfigStore from './AppConfigStore';
import PlayerBalanceStore from './PlayerBalanceStore';

import { DrawsStoreInterface } from '../../shared/interfaces/Stores/DrawsInterface';
import StakesStoreInterface from '../../shared/interfaces/Stores/StakesInterface';
import { LinesStoreInterface } from '../../shared/interfaces/Stores/LinesInterface';
import { TicketStoreInterface } from '../../shared/interfaces/Stores/TicketInterface';
import BetDetailsStoreInterface from '../../shared/interfaces/Stores/BetDetailsInterface';
import InstructionStoreInterface from '../../shared/interfaces/Stores/InstructionInterface';
import ModalStoreInterface from '../../shared/interfaces/Stores/ModalInterface';
import UserBetsStoreInterface from '../../shared/interfaces/Stores/UserBetsInterface';
import LineCostStoreInterface from '../../shared/interfaces/Stores/LineCostInterface';
import ConfigStoreInterface from '../../shared/interfaces/Stores/ConfigStoreInterface';
import { I18nStoreInterface } from '../../shared/interfaces/Stores/I18nStoreInterface';
import { GameStoreInterface } from '../../shared/interfaces/Stores';
import LotteriesStore from './LotteriesStore';
import NavigationStore from './NavigationStore';
import { NavigationStoreInterface } from '../../shared/interfaces/Stores/NavigationStoreInterface';
import LobbyStore from './LobbyStore';
import { IS_RETAIL } from '../../shared/utils';
import RetailStore from './RetailStore';
import RetailDrawResults from './RetailDrawResultsStore';
import RetailDrawResultsStore from './RetailDrawResultsStore';
import PurchaseStore from './PurchaseStore';
import RetailCashierStore from './RetailCashierStore';
import BrandStore from './BrandStore';

export class RootStore {
  betDetails: BetDetailsStoreInterface;
  game: GameStoreInterface;
  instruction: InstructionStoreInterface;
  lines: LinesStoreInterface;
  modal: ModalStoreInterface;
  stake: StakesStoreInterface;
  userBets: UserBetsStoreInterface;
  ticket: TicketStoreInterface;
  draws: DrawsStoreInterface;
  lineCost: LineCostStoreInterface;
  lotteryConfig: ConfigStoreInterface;
  i18n: I18nStoreInterface;
  appConfig: AppConfigStore;
  lotteries: LotteriesStore;
  navigation: NavigationStoreInterface;
  lobby: LobbyStore;
  retail: RetailStore;
  retailDrawResults: RetailDrawResults;
  purchase: PurchaseStore;
  playerBalance: PlayerBalanceStore;
  retailCashierStore: RetailCashierStore;
  brandStore: BrandStore;

  constructor() {
    this.appConfig = new AppConfigStore();
    this.navigation = new NavigationStore();
    this.playerBalance = new PlayerBalanceStore(this.appConfig);
    this.lobby = new LobbyStore();
    this.betDetails = new BetDetailsStore(this.appConfig);
    this.instruction = new InstructionsStore();
    this.modal = new ModalStore();
    this.userBets = new UserBetsStore();
    this.lotteryConfig = new ConfigStore();
    this.i18n = new I18nStore();
    this.lotteries = new LotteriesStore();
    this.game = new GamesStore(this.lotteryConfig, this.lotteries, this.appConfig, this.retail, this.playerBalance);
    this.brandStore = new BrandStore(this.appConfig);

    if (IS_RETAIL) {
      this.purchase = new PurchaseStore(this.appConfig);
      this.retail = new RetailStore(this.purchase, this.lotteries, this.modal);
      this.retailDrawResults = new RetailDrawResultsStore();
      this.retailCashierStore = new RetailCashierStore(this.appConfig);
    }
  }
}

export const RootStoreContext = createContext(new RootStore());
