import React, { useContext, useEffect, useMemo } from 'react';
import { observer } from 'mobx-react';

import Bar from '../../../../../shared/components/Bar/Bar';
import Text from '../../../../../shared/components/Text/Text';
import Selection from '../../../../../shared/components/Selection/Selection';

import { RootStoreContext } from '../../../../../lobby-engine/stores/RootStore';

import ownStyles from './InstantTicketsBar.scss';
import styles from '../../../../assets/styles/index.scss';

const InstantTicketsBar: React.FC<{}> = observer(() => {
  const instantGameStore = useContext(RootStoreContext).game.instantGame;
  const instantGame = useMemo(() => instantGameStore, [instantGameStore]);

  useEffect(() => {
    return (): void => {
      instantGame.stage.clear();
    };
  }, [instantGame.stage]);

  function renderError(): JSX.Element {
    return <Text align="center" type="light" size="medium" fontWeight="bold" textId="instant.tickets.error" />;
  }

  function renderInfo(): JSX.Element {
    return (
      <div>
        <Text
          className={ownStyles.barHeader}
          align="center"
          size="large"
          type="light"
          fontWeight="bold"
          formattedMsgValues={{ status: instantGame.stage.status }}
          textId="instant.tickets.info.header"
        />
        <Text
          align="center"
          size="small"
          type="light"
          formattedMsgValues={{ status: instantGame.stage.status }}
          textId="instant.tickets.info.p1"
        />
      </div>
    );
  }

  function renderNumbers(): JSX.Element {
    const className = ownStyles.number;

    return (
      <div>
        <Selection
          selection={instantGame.winningSelection.selectionsArray}
          numberClassName={className}
          isInstantLottery
        />
      </div>
    );
  }

  function renderContent(): JSX.Element {
    if (instantGame.stage.displayError) {
      return renderError();
    }

    if (instantGame.stage.displaySelection) {
      return renderNumbers();
    }

    return renderInfo();
  }

  return (
    <Bar
      className={`${styles['p-2']} ${ownStyles.bar}`}
      type={instantGame.stage.displaySelection ? 'info' : instantGame.stage.type}
    >
      {renderContent()}
    </Bar>
  );
});

export default InstantTicketsBar;
