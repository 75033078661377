import React, { useContext, useEffect } from 'react';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';

import GameTopBar from '../components/GamesPicker/Game/GameTopBar';

import { RootStoreContext } from '../../lobby-engine/stores/RootStore';
import Money from '../../lobby-engine/entities/BetCostEntity';

import { currencyFormatter, IS_LTECH_FAST, constans, classNames, getHumanAmount } from '../../shared/utils';

import useMobileWidth from '../../shared/hooks/useMobileWidth';

import { ConfigContext } from '../../shared/context';

import { DrawsStore } from '../../lobby-engine/stores';

import mainStyles from '../assets/styles/index.scss';
import styles from '../components/GamesPicker/Game/GameTopBar/GameTopBar.scss';

interface GameTopBarContainerProps {
  onClose: CallableFunction;
  fastPicker: boolean;
  turboPicker: boolean;
  draws: DrawsStore;
}

const GameTopBarContainer: React.FC<GameTopBarContainerProps> = observer((props: GameTopBarContainerProps) => {
  const { onClose, fastPicker, turboPicker, draws } = props;
  const { game, playerBalance } = useContext(RootStoreContext);
  const appConfiguration = useContext(ConfigContext);
  const lotteryStructureName = game.lotteryStructureName;
  const drawJackpot = draws.nearestDraw ? draws.nearestDraw.jackpot : null;
  const jackpotNumber = drawJackpot ? new Money(draws.nearestDraw.jackpot) : 0;
  const jackpotAmount = jackpotNumber ? currencyFormatter(jackpotNumber.currency, jackpotNumber.getHumanAmount()) : '';
  const timesStake = game.gameStructure.data.highestPossiblePrize
    ? game.gameStructure.data.highestPossiblePrize.prizeType === 'jackpot'
      ? game.gameStructure.data.highestPossiblePrize.jackpotAmount.amount
      : game.gameStructure.data.highestPossiblePrize.realAmount
      ? game.gameStructure.data.highestPossiblePrize.realAmount.amount
      : ''
    : '';
  const isWinUp = constans.lotteryNamesDrawSelection.includes(lotteryStructureName);
  const isMobile = useMobileWidth();
  const isInstantBet = game.gameStructure.data.allowInstantBets;

  useEffect(() => {
    if (isInstantBet) {
      playerBalance.getPlayerBalance(game.gameId);
    }
  }, [game.gameId, isInstantBet, playerBalance]);

  const getWinUpToFormattedValue = (value: number): string => {
    return value.toLocaleString(appConfiguration.lang ? appConfiguration.lang : 'EN');
  };

  const getJackpot = (): JSX.Element | string => {
    if (!isWinUp && fastPicker) {
      return <FormattedMessage id={'fast.topbar.subtitle'} />;
    }

    if (isWinUp) {
      return (
        <FormattedMessage
          id={'game.top.bar.win.up'}
          values={{ value: getWinUpToFormattedValue(getHumanAmount(Number(timesStake))) }}
        />
      );
    }

    return jackpotAmount;
  };

  const renderLogo = (): JSX.Element => {
    if (isMobile && IS_LTECH_FAST) {
      const image = game.logo;

      return (
        <div className={styles.gameLogoStickyWrapper}>
          <div onClick={(): void => onClose()} className={`${mainStyles.closeIconWrapper} ${styles.closeIconWrapper}`}>
            <span className={`${mainStyles.closeIcon} ${styles.closeIcon}`}></span>
          </div>
          {isInstantBet && playerBalance.data && (
            <div className={styles.playerBalance}>{playerBalance.data.toString()}</div>
          )}
          <div className={styles.infoBoxWrapper}>
            {image && <img className={styles.gameLogo} src={image.url || ''} alt="logo" />}
            <div className={styles.infoBox}>
              <p className={styles.lotteryName}>{game.displayName}</p>
              <p
                className={classNames(styles.jackpotTxt, {
                  [styles.winUpTxt]: isWinUp,
                })}
              >
                {getJackpot()}
              </p>
            </div>
          </div>
        </div>
      );
    }

    return null;
  };
  return (
    <>
      {renderLogo()}
      <GameTopBar
        areInstantBets={game.gameStructure.data.allowInstantBets}
        playerBalance={playerBalance.data ? playerBalance.data.toString() : null}
        name={game.displayName}
        {...{ jackpotAmount, onClose, fastPicker, turboPicker, lotteryStructureName, timesStake }}
        image={game.gameStructure.data.image}
        nearestDraw={draws.nearestDraw}
        getNearestDraw={draws.getNearestDraw}
        countdown={draws.countdown}
      />
    </>
  );
});

export default GameTopBarContainer;
