export const ERROR_MODAL = 'ERROR_MODAL';
export const SUCCESS_MODAL = 'SUCCESS_MODAL';
export const GAME_MODAL = 'GAME_MODAL';
export const TEST_MODAL = 'TEST_MODAL';
export const PERMUTATIONS_MODAL = 'PERMUTATIONS_MODAL';
export const PICKER_MODAL = 'PICKER_MODAL';
export const TICKET_SUMMARY_MODAL = 'TICKET_SUMMARY_MODAL'; //Show before purchase, as a kind of "Are you sure" box
//Shows ticket receipt/retail modal for single ticket
export const TICKET_MODAL = 'TICKET_MODAL';
//After purchase, a multi ticket modal for printing is show
export const MULTI_TICKET_RECEIPT_MODAL = 'MULTI_TICKET_RECEIPT_MODAL';
export const LOADER_MODAL = 'LOADER_MODAL';
export const PENDING_MODAL = 'PENDING_MODAL';
export const DEPOSIT_MODAL = 'DEPOSIT_MODAL';
