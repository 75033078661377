import { observable } from 'mobx';

import { TICKET_STATUS_DEFAULT, TICKET_STATUS_LOST, TICKET_STATUS_WON } from '../utils/ticketsConstans';

export default class InstantGameStage {
  private readonly TIMEOUT_TIME = 7000;
  private stageTimeout: NodeJS.Timeout = null;
  public onTimeoutClear: () => void = null;
  @observable type: 'info' | 'success' | 'danger' = 'info';
  @observable displaySelection = false;
  @observable displayInfo = true;
  @observable displayError = false;
  @observable defaultStage = true;
  @observable isLogicPending = false;
  @observable status = TICKET_STATUS_DEFAULT;

  private setDefaultStage(): void {
    this.defaultStage = true;
    this.displayError = false;
    this.type = 'info';
    this.status = TICKET_STATUS_DEFAULT;
    this.isLogicPending = false;
    this.setDisplayInfo();
  }

  private setSuccessStage(): void {
    this.defaultStage = false;
    this.type = 'success';
    this.status = TICKET_STATUS_WON;
  }

  private setLostStage(): void {
    this.defaultStage = false;
    this.type = 'danger';
    this.status = TICKET_STATUS_LOST;
  }

  private setDisplayInfo(): void {
    this.displayInfo = true;
    this.displaySelection = false;
  }

  private setDefaultStageTimer(): void {
    this.setTimer((): void => {
      this.setDefaultStage();
      this.startTimeout();
    });
  }

  private setTimer(callback: () => void): void {
    this.clear();

    this.stageTimeout = global.setTimeout(callback, this.TIMEOUT_TIME);
  }

  public clear(): void {
    if (typeof this.onTimeoutClear === 'function' && this.defaultStage) {
      this.onTimeoutClear();
      this.onTimeoutClear = null;
    }

    clearTimeout(this.stageTimeout);
  }

  public setErrorStage(): void {
    this.displayError = true;
    this.displayInfo = false;
    this.defaultStage = false;
    this.type = 'danger';
  }

  public setDisplaySelection(): void {
    this.displaySelection = true;
    this.displayInfo = false;
  }

  public setProperStage(status: string): void {
    this.isLogicPending = true;

    if (status === TICKET_STATUS_WON) {
      this.setSuccessStage();

      return;
    } else if (status === TICKET_STATUS_LOST) {
      this.setLostStage();

      return;
    }

    return;
  }

  public startTimeout(): void {
    if (this.defaultStage) {
      return this.clear();
    }

    if (this.displayError) {
      this.setDefaultStageTimer();

      return;
    }

    if (this.displaySelection && !this.displayInfo) {
      this.setTimer((): void => {
        this.setDisplayInfo();
        this.startTimeout();
      });
    } else {
      this.setDefaultStageTimer();
    }
  }
}
