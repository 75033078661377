import React from 'react';
import { observer } from 'mobx-react';

import ResultSelection from '../../../lobby-engine/entities/selections/ResultSelection';

import { classNames } from '../../utils';

import ownStyles from './Selection.scss';
import Delayed from '../Delayed/Delayed';

interface SelectionProps {
  selection: ResultSelection[];
  numberClassName?: string;
  isInstantLottery?: boolean;
}

const Selection: React.FC<SelectionProps> = observer(
  ({ selection, numberClassName, isInstantLottery }: SelectionProps) => {
    function renderNumber(selectedNumber: number, index: number): JSX.Element {
      return (
        <div
          key={`${selectedNumber}-${index}`}
          className={classNames(ownStyles.number, {
            [numberClassName]: typeof numberClassName === 'string',
            [ownStyles.numberAnimation]: isInstantLottery,
          })}
        >
          {selectedNumber}
        </div>
      );
    }

    function renderNumbers(numbers: number[]): JSX.Element[] {
      let time = 0;

      return numbers.map((elem: number, index: number) => {
        if (isInstantLottery) {
          const BALL_SHOW_TIMEOUT = 1500;
          time += BALL_SHOW_TIMEOUT;

          return (
            <Delayed key={`number_${index}`} timeBeforeShow={time}>
              {renderNumber(elem, index)}
            </Delayed>
          );
        } else {
          return renderNumber(elem, index);
        }
      });
    }

    function renderSelection(): JSX.Element[][] {
      return selection.map((elem: ResultSelection) => {
        // for now just display only main numbers
        if (elem.isSubType || elem.name !== 'main') {
          return null;
        }

        return renderNumbers(elem.numbers);
      });
    }

    return <div className={ownStyles.selection}>{renderSelection()}</div>;
  }
);

export default Selection;
