import React from 'react';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';

import Stake from '../Stake';

import { NumberOfDrawsInterface } from '../../../../../shared/interfaces/GamesPicker';
import PostBetResponseInterface from '../../../../../shared/interfaces/GamesPicker/PostBetResponseInterface';
import { ErrorModalInterface } from '../../../../../shared/interfaces/Lobby/ModalsInterface';

import { classNames, currencyFormatter } from '../../../../../shared/utils';
import { TICKET_SUMMARY_MODAL, DEPOSIT_MODAL, PENDING_MODAL } from '../../../../../shared/utils/modalNames';
import { IS_LUCKYBALLS_CART, IS_RETAIL } from '../../../../../shared/utils/themes';

import styles from './GameSummary.scss';
import StakeEntity from '../../../../../lobby-engine/entities/StakeEntity';

import { WalletInterface } from '../../../../../shared/interfaces/GlobalInterfaces';
interface GameSummaryProps {
  numberDraws: NumberOfDrawsInterface[];
  activeStake: StakeEntity;
  betCost: number;
  currency: string;
  areNumbersPicked: boolean;
  stakes: StakeEntity[];
  summaryInfoTxt: JSX.Element;
  drawsCount: number;
  sendingBet: boolean;
  fastPicker?: boolean;
  isBetPermutated: boolean;
  betLogicPending?: boolean;
  isInstant?: boolean;
  playerWallet?: WalletInterface;
  playerBalance?: string;
  lotteryName: string;
  onClose(): void;
  onDrawClick(numberDrawId: number): void;
  onStakeClick(stake: StakeEntity): void;
  onProceedBtnClick(): Promise<PostBetResponseInterface>;
  onConfirmClick(): void;
  showModal(
    name: string,
    data:
      | ErrorModalInterface
      | {
          cancellation: boolean;
        }
  ): void;
}

const GameSummary: React.FC<GameSummaryProps> = observer((props: GameSummaryProps) => {
  const {
    numberDraws,
    betCost,
    currency,
    onDrawClick,
    onStakeClick,
    onProceedBtnClick,
    areNumbersPicked,
    stakes,
    activeStake,
    summaryInfoTxt,
    drawsCount,
    sendingBet,
    showModal,
    fastPicker,
    isBetPermutated,
    betLogicPending,
    isInstant,
    onConfirmClick,
    playerWallet,
    lotteryName,
    playerBalance,
  } = props;

  const handleProceedButton = async (): Promise<void> => {
    let playerCredit = null;

    if (lotteryName.toLowerCase().includes('imprexis')) {
      if (playerWallet.blnOK) {
        playerCredit = playerWallet.obj.fltEffectiveBalanceInCents;
      }
    } else {
      playerCredit = parseFloat(playerBalance);
    }

    if (playerCredit) {
      if (betCost > playerCredit) {
        return showModal(DEPOSIT_MODAL, { cancellation: false });
      }
    }

    if (IS_RETAIL) {
      showModal(TICKET_SUMMARY_MODAL, { cancellation: false });

      return;
    }

    // const betInfo = await onProceedBtnClick();

    // if(!betInfo) {
    //   return null;
    // }

    onProceedBtnClick();

    if (!isInstant) {
      showModal(PENDING_MODAL, null);
    }
  };

  const renderNumberDraws = (): JSX.Element[] => {
    return numberDraws.map((elem: NumberOfDrawsInterface, index: number) => {
      return (
        <div
          key={`${index}-${elem.value}`}
          className={classNames(`${styles.drawPickBtn}`, {
            [styles.drawPickBtnActive]: elem.active,
            [styles.drawPickBtnDisable]: elem.disable || drawsCount < elem.value || isBetPermutated,
          })}
          onClick={(): void => onDrawClick(index)}
        >
          <FormattedMessage id="sidebar.drawnumber" values={{ numberOfDraws: elem.value }} />
        </div>
      );
    });
  };

  return (
    <React.Fragment>
      <div className={styles.gameSummaryContainer}>
        {!isInstant && (
          <div className={styles.drawsPickContainer}>
            <p className={styles.drawsPickHead}>
              <FormattedMessage id="sidebra.title2" />
            </p>
            <div
              className={classNames(`${styles.drawsPicker}`, {
                [styles.drawsPickerFast]: fastPicker,
              })}
            >
              {renderNumberDraws()}
            </div>
          </div>
        )}
        {stakes.length > 0 && (
          <Stake {...{ currency, fastPicker }} data={stakes} activeOption={activeStake} onOptionClick={onStakeClick} />
        )}
        <div
          className={classNames(`${styles.summaryPrizeContainer}`, {
            [styles.summaryPrizeFastContainer]: fastPicker,
          })}
        >
          <p className={styles.summaryInfoText}>{summaryInfoTxt}</p>
          <p data-cy="summaryPrize" className={styles.summaryPrizeTxt}>
            <FormattedMessage id="sidebar.total" />
            {currencyFormatter(currency, betCost, 0)}
          </p>
          <div
            data-testid="button-play-now"
            className={classNames(styles.summaryConfirmBtn, {
              [styles.summaryConfirmBtnActive]: areNumbersPicked,
              [styles.summaryConfirmBtnDisable]: sendingBet || betLogicPending,
            })}
            onClick={IS_LUCKYBALLS_CART ? onConfirmClick : handleProceedButton}
          >
            <FormattedMessage id="sidebar.btn.submit" />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
});

export default GameSummary;
