import ApiInstantGameInterface from '../../shared/interfaces/Api/ApiInstantTickets';
import { GameBetBodyInterface } from '../../shared/interfaces/GamesPicker';
import { InstantTicketInterface } from '../../shared/interfaces/GamesPicker/TicketInterface';

class ApiInstantGameConnector {
  public api: ApiInstantGameInterface;

  public constructor(api: ApiInstantGameInterface) {
    this.api = api;
  }

  async postInstantTickets(body: GameBetBodyInterface, gameId: string, token: string): Promise<InstantTicketInterface> {
    const response = await this.api.postInstantTickets(body, gameId, token);

    if (!response.ok) {
      throw await response.json();
    }

    return await response.json();
  }
}

export default ApiInstantGameConnector;
